#skill-intro {
    margin-top: 100px;
    color:white;
    font-family: 'Varela Round', sans-serif;
}
#skill-cards {
    margin: 25px;
    display: flex;
    flex-wrap: wrap;
    top: 50px;
}