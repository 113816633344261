@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&family=Rajdhani:wght@500&family=Varela+Round&display=swap');
#greet {
    min-width: 300px;
    background: rgba(255, 255, 255, 0.07);
    margin-top: 0;
    padding: 20px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
    color: white;

}
#greet h2{
    font-family: 'Barlow Condensed', sans-serif;
}

#job {
    margin-top: 5px;
    margin-bottom: 5px;
}

#location {
    color: white;
}

#greeting-div {
    margin-top: 80px;
    background: transparent;
    box-shadow: none;
    display: flex;
    justify-content: center;
}

#profile-links {
    position: absolute;
    bottom: 0px;
}

#profile-link-ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#profile-link-ul a {
    color: white;
}

.profile-link-li {
    padding-right: 10px;
}