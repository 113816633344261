#tsparticles {
    background:
    linear-gradient(to left, rgb(23, 0, 65), transparent),
    linear-gradient(to bottom left,rgb(255, 0, 157), transparent),
    linear-gradient(to top left, rgb(0, 238, 255), transparent),
    linear-gradient(to top right, rgb(140, 0, 255), transparent);
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: -1;
}
