@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed&family=Rajdhani:wght@500&family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed&family=Rajdhani:wght@500&family=Varela+Round&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#tsparticles {
    background:
    linear-gradient(to left, rgb(23, 0, 65), transparent),
    linear-gradient(to bottom left,rgb(255, 0, 157), transparent),
    linear-gradient(to top left, rgb(0, 238, 255), transparent),
    linear-gradient(to top right, rgb(140, 0, 255), transparent);
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: -1;
}

#greet {
    min-width: 300px;
    background: rgba(255, 255, 255, 0.07);
    margin-top: 0;
    padding: 20px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
    color: white;

}
#greet h2{
    font-family: 'Barlow Condensed', sans-serif;
}

#job {
    margin-top: 5px;
    margin-bottom: 5px;
}

#location {
    color: white;
}

#greeting-div {
    margin-top: 80px;
    background: transparent;
    box-shadow: none;
    display: flex;
    justify-content: center;
}

#profile-links {
    position: absolute;
    bottom: 0px;
}

#profile-link-ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#profile-link-ul a {
    color: white;
}

.profile-link-li {
    padding-right: 10px;
}
#skill-list {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 8px;
    margin: 5px;
    width: 133px;
    font-family: 'Rajdhani', sans-serif;
}

#skill-header {
    color: white;
}
#skill-intro {
    margin-top: 100px;
    color:white;
    font-family: 'Varela Round', sans-serif;
}
#skill-cards {
    margin: 25px;
    display: flex;
    flex-wrap: wrap;
    top: 50px;
}
#link-card {
    margin-bottom: 50px;
    max-width: 700px;
}
#upper-break-line {
    margin-top: 100px;
}

.recent-work {
    width: 85%;
    margin: 25px;
    display:flex;
    justify-content: center;
    align-content: center;
    
}

#recent-work-grid {
    display:flex;
    justify-content: center;
    align-content: center;
    margin-top: 60px;
}

#recent-work-heading {
    color: white;
    font-family: 'Varela Round', sans-serif;
}
